.box__icons{
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.box__heading {
  font-size: 1.65rem;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: .5rem;
}

.box__para {
  font-size: .85rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.box__footer {
  display: flex;
  justify-content: space-around;
  font-size: .85rem;
  flex-wrap: wrap;
}