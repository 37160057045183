.logo-svg{
  width: 75px;
  height: auto;
  stroke: #fff;
  stroke-width: 16;
  stroke-linejoin: round;
  overflow: visible;
}

.logo-svg--dark{
  width: 75px;
  height: auto;
  stroke: #000;
  stroke-linejoin: round;
  stroke-width: 16;
  overflow: visible;
}